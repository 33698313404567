import api from './api';

const URLS = {
  login: 'login',
  logout: 'logout',
  prepareApp: 'prepareApp',
  prepareScheduler: 'prepareScheduler',
  prepareCalendar: 'prepareCalendar',
  changeProperty: 'changeProperty',
};

export const login = (credentials) => {
  return api.post(URLS.login, credentials);
};

export const logout = () => {
  return api.post(URLS.logout);
};

export const prepareApp = () => {
  return api.post(URLS.prepareApp);
};

export const prepareScheduler = (prepareSchedulerRequest) => {
  return api.post(URLS.prepareScheduler, prepareSchedulerRequest);
};

export const changeProperty = (changePropertyRequest) => {
  return api.post(URLS.changeProperty, changePropertyRequest);
};

export const prepareCalendar = (prepareCalendarRequest) => {
  return api.post(URLS.prepareCalendar, prepareCalendarRequest);
};
