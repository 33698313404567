import React from 'react';
import { Form } from 'semantic-ui-react';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import { createUseStyles } from 'react-jss';
import { EN_US, FORMAT2 } from '@/helpers/dateHelper';

const useStyles = createUseStyles(
  {
    root: {
      width: '12rem',
      '& .field': {
        width: '100%',
      },
    },
  },
  { name: 'DatepickerField' },
);

const DatepickerField = ({ id, ...props }) => {
  const classes = useStyles();

  return (
    <Form.Field className={classes.root}>
      <SemanticDatepicker
        locale={EN_US.code}
        format={FORMAT2}
        id={id}
        type="basic"
        icon="calendar outline"
        iconPosition="right"
        clearable={props.clearable ? props.clearable : false}
        datePickerOnly
        {...props}
      />
    </Form.Field>
  );
};

export default React.memo(DatepickerField);
