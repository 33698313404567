import React from 'react';
import { createUseStyles } from 'react-jss';
import Colors from '@/constants/colors';
import Breakpoints from '@/constants/breakpoints';

const useStyles = createUseStyles(
  {
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '100vh',
    },
    content: {
      width: 500,
      padding: '3rem',
      margin: '2rem',
      border: `1px solid ${Colors.grayTwo}`,
      borderRadius: 4,
    },
    [`@media (max-width: ${Breakpoints.md}px)`]: {
      content: {
        width: '100%',
      },
    },
  },
  { name: 'BasicLayout' },
);

const BasicLayout = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.content}>{children}</div>
    </div>
  );
};

export default React.memo(BasicLayout);
