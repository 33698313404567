import React, { useState, useEffect } from 'react';
import { Search, Transition, Icon } from 'semantic-ui-react';
import { createUseStyles } from 'react-jss';
import _ from 'lodash';
import Breakpoints from '@/constants/breakpoints';
import isMobile from '@/helpers/mobileHelper';
import { sessionSelector } from '@/store/selector';
import { Dimmer } from 'semantic-ui-react';

const initialState = {
  loading: false,
  results: [],
  value: '',
  open: false,
};

const useStyles = createUseStyles(
  {
    root: {
      paddingRight: '1rem',
      '& > .results': {
        maxHeight: `${window.scrollY + window.innerHeight - 72}px`,
        overflow: 'auto',
        width: '39rem !important',
      },
      '& > .results .category .results': {
        width: '100% !important',
      },
    },
    [`@media (max-width: ${Breakpoints.lg}px)`]: {
      root: {
        '& > .results': {
          width: '30rem !important',
        },
      },
    },
    [`@media (max-width: ${Breakpoints.md}px)`]: {
      root: {
        width: '1000px',
        '& > .results': {
          width: '25rem !important',
        },
      },
    },
    showMore: {
      textAlign: 'center',
      padding: '1rem',
    },
    ShowMoreLoader: {
      background: '#F3F4F526 !important',
    },
  },
  { name: 'SearchField' },
);

const ShowMoreRow = ({ handleShowMore, description, classes }) => {
  const [loading, setLoading] = useState(false);
  return (
    <Dimmer.Dimmable dimmed={loading}>
      <Dimmer active={loading} inverted className={classes.ShowMoreLoader}></Dimmer>
      <a
        onClick={() => {
          setLoading(true);
          handleShowMore(description);
        }}
      >
        <div className={classes.showMore}>
          <div className="description">{loading ? 'Loading...' : 'Show More'}</div>
        </div>
      </a>
    </Dimmer.Dimmable>
  );
};

const SearchField = ({ apiService }) => {
  const classes = useStyles();
  const [state, setState] = useState(initialState);
  const [visible, setVisible] = useState(false);
  const session = sessionSelector();
  const user = session.user;

  const handleResultSelect = () => {
    //setState({ value: result.title });
    // console.log(result);
    //setState({ value: '', open: true });
  };

  useEffect(() => {
    setState(initialState);
  }, [user?.selectedProperty]);

  const onError = (e) => {
    setState(initialState);
    console.log(e);
  };

  const handleSearchChange = (e, { value }) => {
    if (!isLoading) setState({ isLoading: true });
    if (value?.length <= 3) {
      setState({ isLoading: false });
      return;
    }
    apiService({ query: value, category: null })
      .then((res) => {
        setState({
          isLoading: false,
          results: res.data.result,
          query: value,
          open: true,
        });
      })
      .catch(onError);
  };

  const onFocus = () => {
    setVisible(true);
  };

  const onBlur = () => {
    setVisible(false);
    setState({ ...state, open: false });
  };

  const resultRenderer = ({ title, description }) => {
    if (title == 'ShowMore') {
      return (
        <ShowMoreRow description={description} classes={classes} handleShowMore={handleShowMore} />
      );
    } else {
      return (
        <div className="content">
          <div className="title">{title}</div>
          <div className="description">{description}</div>
        </div>
      );
    }
  };

  const { isLoading, value, results, query, open } = state;

  const handleShowMore = (category) => {
    apiService({ query: query, category: category })
      .then((res) => {
        setState({
          isLoading: false,
          results: res.data.result,
          query: query,
          open: true,
        });
        setVisible(true);
      })
      .catch(onError);
  };

  return (
    <>
      <div>
        {(visible || !isMobile.matches) && (
          <Search
            category
            loading={isLoading}
            onResultSelect={handleResultSelect}
            onSearchChange={_.debounce(handleSearchChange, 500, {
              leading: true,
            })}
            resultRenderer={resultRenderer}
            results={results}
            value={value}
            minCharacters={4}
            className={classes.root}
            input={{ icon: 'search', iconPosition: 'left' }}
            onBlur={onBlur}
            open={open}
            onFocus={() => setState({ ...state, open: true })}
          />
        )}
      </div>
      <Transition.Group animation={'fade left'} duration={500}>
        {!visible && isMobile.matches && (
          <div onClick={onFocus}>
            <Icon disabled name="search" size="large" />
          </div>
        )}
      </Transition.Group>
    </>
  );
};

export default React.memo(SearchField);
