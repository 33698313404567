import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(
  {
    header: (properties) => ({
      background: properties.backgroundColor,
      color: '#fff',
      display: 'flex',
      height: '45px',
      padding: '11px',
    }),
    close: {
      color:' #fff',
      fontSize: '16px',
      fontWeight: 'bold',
      position: 'absolute',
      right: '16px',
      cursor: 'pointer',
      '&:hover': {
        color:' #fff',
      }
    },
    reservation: {
      width: '110px'
    } 
  },
  { name: 'QuickViewForm' },
);


const QuickViewForm = ({event, onClose}) => {
  const classes = useStyles({backgroundColor: event != null ? event.bgColor : ""});
  return (
    <>
      <div className={classes.header}>
        <div className={classes.reservation}>#{event.id}</div>
        <div>{event.customer.lastName} {event.customer.firstName}</div>
        <a className={classes.close} onClick={onClose}>X</a>
      </div>
    </>
  );
};

export default React.memo(QuickViewForm);
