import React from 'react';
import SlideForm from '../SlideForm';
import QuickViewForm from './QuickViewForm';

const QuickViewModal = ({ open, onClose, event }) => {
  return (
    <SlideForm.Content visible={open} side="right" height="100%" onBlur={onClose}>
      <QuickViewForm onClose={onClose} event={event} />
    </SlideForm.Content>
  );
};

export default React.memo(QuickViewModal);
