import React, { useEffect } from 'react';
import './WaveScheduler.css';
// import Scheduler from './react-big-scheduler-wave';
import Scheduler from 'react-big-scheduler-stch';
// import { SchedulerData, ViewType, DATE_FORMAT } from './react-big-scheduler-wave';
import { SchedulerData, ViewType, CellUnit } from 'react-big-scheduler-stch';
// import './react-big-scheduler-wave/css/style.css';
import 'react-big-scheduler-stch/lib/css/style.css';
import withDragDropContext from './withDnDContext';
import Colors from '@/constants/colors';
import DateHelper from '@/helpers/dateHelper';
import { FORMAT4 } from '@/helpers/dateHelper';
import isMobile from '@/helpers/mobileHelper';
import { Icon } from 'semantic-ui-react';
import Col from 'antd/lib/col';
import Row from 'antd/lib/row';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(
  {
    root: (propertyWidth) => ({
      '& .scheduler-bg-table thead th': {
        maxWidth: `${propertyWidth.cellWidth}px`,
      },
      '& .scheduler-view': {
        width: `${propertyWidth.totalCellWidth}px !important`,
        maxWidth: `${propertyWidth.totalCellWidth}px`,
      },
    }),
  },
  { name: 'WaveScheduler' },
);

const WaveScheduler = ({
  events = [],
  resources = [],
  /* onChange,
  onCalendarViewChange, */
  calendarConfiguration,
  setLoading,
}) => {
  let config = calendarConfiguration.configuration();
  if (!isMobile.matches) {
    config.resourceName = DateHelper.getMonthName(calendarConfiguration.selectedDate).toUpperCase();
  } else {
    config.resourceName = DateHelper.getMonthName(calendarConfiguration.selectedDate);
  }
  const classes = useStyles({
    cellWidth: calendarConfiguration.cellWidth,
    totalCellWidth: calendarConfiguration.totalCellWidth,
  });

  const schedulerData = new SchedulerData(
    calendarConfiguration.selectedDate,
    calendarConfiguration.view,
    false,
    false,
    config,
    calendarConfiguration.behaviors(),
  );

  /* Recalculate span when using custom view */
  schedulerData._getSpan = (startTime, endTime, headers) => {
    if (schedulerData.showAgenda) return 1;

    const timeBetween = (date1, date2, timeIn) => {
      if (timeIn === 'days' || timeIn === 'day') {
        if (date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth()) {
          return 1;
        }
      }

      let one;
      switch (timeIn) {
        case 'days':
        case 'day':
          one = 1000 * 60 * 60 * 24;
          break;
        case 'minutes':
        case 'minute':
          one = 1000 * 60;
          break;
        default:
          return 0;
      }

      const date1_ms = date1.getTime();
      const date2_ms = date2.getTime();

      const diff = (date2_ms - date1_ms) / one;
      return diff < 0 ? 0 : diff;
    };

    let eventStart = new Date(startTime),
      eventEnd = new Date(endTime),
      span = 0,
      windowStart = new Date(schedulerData.startDate),
      windowEnd = new Date(schedulerData.endDate);
    windowStart.setHours(0, 0, 0, 0);
    windowEnd.setHours(23, 59, 59);
    if (schedulerData.viewType === ViewType.Day) {
      if (headers.length > 0) {
        const day = new Date(headers[0].time);
        if (day.getDate() > eventStart.getDate() && day.getDate() < eventEnd.getDate()) {
          span = 1440 / schedulerData.config.minuteStep;
        } else if (day.getDate() > eventStart.getDate() && day.getDate() === eventEnd.getDate()) {
          span = Math.ceil(timeBetween(day, eventEnd, 'minutes') / schedulerData.config.minuteStep);
        } else if (day.getDate() === eventStart.getDate() && day.getDate() < eventEnd.getDate()) {
          day.setHours(23, 59, 59);
          span = Math.ceil(
            timeBetween(eventStart, day, 'minutes') / schedulerData.config.minuteStep,
          );
        } else if (
          (day.getDate() === eventStart.getDate() && day.getDate() === eventEnd.getDate()) ||
          eventEnd.getDate() === eventStart.getDate()
        ) {
          span = Math.ceil(
            timeBetween(eventStart, eventEnd, 'minutes') / schedulerData.config.minuteStep,
          );
        }
      }
    } else if (
      schedulerData.viewType === ViewType.Week ||
      schedulerData.viewType === ViewType.Month ||
      schedulerData.viewType === ViewType.Quarter ||
      schedulerData.viewType === ViewType.Year
    ) {
      const startDate = windowStart < eventStart ? eventStart : windowStart;
      const endDate = windowEnd > eventEnd ? eventEnd : windowEnd;
      span = Math.ceil(timeBetween(startDate, endDate, 'days'));
    } else {
      /* if (this.cellUnit === CellUnit.Day) {
        eventEnd.setHours(23, 59, 59);
        eventStart.setHours(0, 0, 0, 0);
      }*/

      const timeIn = schedulerData.cellUnit === CellUnit.Day ? 'days' : 'minutes';
      const dividedBy =
        schedulerData.cellUnit === CellUnit.Day ? 1 : schedulerData.config.minuteStep;

      if (windowStart >= eventStart && eventEnd <= windowEnd) {
        span = Math.ceil(timeBetween(windowStart, eventEnd, timeIn) / dividedBy);
      } else if (windowStart > eventStart && eventEnd > windowEnd) {
        span = Math.ceil(timeBetween(windowStart, windowEnd, timeIn) / dividedBy);
      } else if (windowStart <= eventStart && eventEnd >= windowEnd) {
        span = Math.ceil(timeBetween(eventStart, windowEnd, timeIn) / dividedBy);
      } else {
        span = Math.ceil(timeBetween(eventStart, eventEnd, timeIn) / dividedBy);
      }
    }

    return span;
  };

  schedulerData.setResources(resources);
  schedulerData.setEvents(events);

  const data = {
    viewModel: schedulerData,
  };

  const eventClicked = (schedulerData, event) => {
    calendarConfiguration.onEventClicked(event);
  };

  const eventItemTemplateResolver = (
    schedulerData,
    event,
    bgColor,
    isStart,
    isEnd,
    mustAddCssClass,
    mustBeHeight,
    agendaMaxEventWidth,
  ) => {
    let borderWidth = isStart ? '4' : '0';
    let borderColor = event.bgColor,
      backgroundColor = event.bgColor;
    let titleText = schedulerData.behaviors.getEventTextFunc(schedulerData, event);
    let divStyle = {
      borderLeft: borderWidth + 'px solid ' + borderColor,
      backgroundColor: backgroundColor,
      height: mustBeHeight,
    };
    if (agendaMaxEventWidth) divStyle = { ...divStyle, maxWidth: agendaMaxEventWidth };

    if (event.inHouse) {
      mustAddCssClass = mustAddCssClass.concat(' ', 'inhouse');
    }

    return (
      <div key={event.id} className={mustAddCssClass} style={divStyle}>
        {event.leftIcon && <Icon name={event.leftIcon.icon} />}
        <span style={{ marginLeft: '4px', lineHeight: `${mustBeHeight}px` }}>{titleText}</span>
      </div>
    );
  };

  const nonAgendaCellHeaderTemplateResolver = (schedulerData, item, formattedDateItems, style) => {
    let datetime = schedulerData.localeDayjs(item.time);
    let isCurrentDate = false;
    let isWeekendDate = false;

    if (schedulerData.viewType === ViewType.Day) {
      isCurrentDate = datetime.isSame(new Date(), 'hour');
    } else {
      isCurrentDate = datetime.isSame(new Date(), 'day');
      isWeekendDate =
        datetime.isSame(datetime.weekday(0), 'day') || datetime.isSame(datetime.weekday(6), 'day');
    }

    if (isCurrentDate) {
      style.backgroundColor = Colors.calendarCurrentDay;
      style.color = 'black';
    }

    if (isWeekendDate) {
      style.backgroundColor = Colors.graySix;
      style.color = 'black';
    }

    return (
      <th key={item.time} className={`header3-text`} style={style}>
        {formattedDateItems.map((formattedItem, index) => {
          var currentFormattedDay =
            '<span style="display:block">' + formattedItem.substring(0, 3) + '</span>';
          var currentFormattedNumber = '<span>' + formattedItem.substring(3) + '</span>';
          return (
            <div
              key={index}
              dangerouslySetInnerHTML={{ __html: currentFormattedDay + currentFormattedNumber }}
            />
          );
        })}
      </th>
    );
  };

  const slotItemTemplateResolver = (
    schedulerData,
    resourceEvents,
    slotClickedFunc,
    width,
    clsName,
  ) => {
    let roomNumber = resourceEvents.slotName.split('-')[0].trim();
    let gridName = resourceEvents.slotName.split('-')[1].trim();
    let style = {};
    let columnStyle = {};

    style.width = width;
    style.height = '100%';
    columnStyle.padding = '0';
    columnStyle.alignItems = 'center';
    columnStyle.display = 'grid';

    return (
      <div
        style={style}
        title={resourceEvents.slotName}
        className={clsName + ' ui two column internally celled grid'}
      >
        <div className="row">
          <div className="column" style={columnStyle}>
            {roomNumber}
          </div>
          <div className="column" style={columnStyle}>
            <span style={{ fontWeight: 'bold' }}>{gridName}</span>
          </div>
        </div>
      </div>
    );
  };

  const eventItemPopoverTemplateResolver = (
    schedulerData,
    eventItem,
    title,
    start,
    end,
    statusColor,
  ) => {
    let subtitleRow = <div />;
    let opsRow = <div />;
    let customer = eventItem.customer;
    return (
      <div style={{ width: '300px' }}>
        <Row type="flex" align="middle">
          <Col span={2}>
            <div className="status-dot" style={{ backgroundColor: statusColor }} />
          </Col>
          <Col span={22} className="overflow-text">
            <span className="header2-text" title={title}>
              {title}
            </span>
          </Col>
        </Row>
        {subtitleRow}
        <Row type="flex" align="middle">
          <Col span={2}>
            <div />
          </Col>
          <Col span={22}>
            {/* <span className="header1-text">{start.format('HH:mm')}</span> */}
            <span className="help-text">{start.format(FORMAT4)}</span>
            <span className="header2-text" style={{ marginLeft: '8px' }}>
              -
            </span>
            {/* <span className="header1-text" style={{ marginLeft: '8px' }}>
              {end.format('HH:mm')}
            </span> */}
            <span className="help-text" style={{ marginLeft: '8px' }}>
              {end.format(FORMAT4)}
            </span>
          </Col>
        </Row>
        {customer?.mobileStandarized && (
          <Row type="flex" align="middle">
            <Col span={2}>
              <div />
            </Col>
            <Col span={22}>
              <span className="help-text">
                <a href={`tel:${customer.mobileStandarized}`}>{customer.mobileStandarized}</a>
              </span>
            </Col>
          </Row>
        )}
        {customer?.email && (
          <Row type="flex" align="middle">
            <Col span={2}>
              <div />
            </Col>
            <Col span={22}>
              <span className="help-text">
                <a href={`mailto:${customer.email}`}>{customer.email}</a>
              </span>
            </Col>
          </Row>
        )}
        {eventItem.paymentStatus &&
          (eventItem.paymentStatus == 'paid' ||
            eventItem.paymentStatus == 'not-paid' ||
            eventItem.paymentStatus == 'parcial-paid') && (
            <Row type="flex" align="middle">
              <Col span={2}>
                <div />
              </Col>
              <Col span={22}>
                <span className="help-text" style={{ color: statusColor, fontWeight: 'bold' }}>
                  {eventItem.paymentStatus == 'paid' && 'Paid in Full'}
                  {(eventItem.paymentStatus == 'parcial-paid' ||
                    eventItem.paymentStatus == 'not-paid') &&
                    `Balance Due: $ ${eventItem.balance.toFixed(
                      2,
                    )} / Paid: $ ${eventItem.totalPaid.toFixed(2)}`}
                </span>
              </Col>
            </Row>
          )}
        {opsRow}
      </div>
    );
  };

  const { viewModel } = data;

  useEffect(() => {
    setLoading(false);
  });

  return (
    <div className={classes.root}>
      <Scheduler
        schedulerData={viewModel}
        eventItemClick={eventClicked}
        eventItemTemplateResolver={eventItemTemplateResolver}
        nonAgendaCellHeaderTemplateResolver={nonAgendaCellHeaderTemplateResolver}
        prevClick={() => {}}
        nextClick={() => {}}
        onViewChange={() => {}}
        onSelectDate={() => {}}
        slotItemTemplateResolver={slotItemTemplateResolver}
        eventItemPopoverTemplateResolver={eventItemPopoverTemplateResolver}
      />
    </div>
  );
};

export default React.memo(withDragDropContext(WaveScheduler));
