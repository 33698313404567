import React from 'react';
import { Navigate } from 'react-router-dom';
import { sessionSelector } from '@/store/selector';

const ProtectedRoute = ({ children }) => {
  const session = sessionSelector();

  if (!session.token) {
    return <Navigate to="/unauthorized" replace />;
  }

  return children;
};
export default React.memo(ProtectedRoute);
