const Colors = {
  blackOne: '#060C18',
  blackTwo: '#231F23',
  grayOne: '#929DAD',
  grayTwo: '#E5E5E5',
  grayThree: '#596074',
  grayFour: '#697386',
  grayFive: '#4F566B',
  graySix: '#e8e8e8cc',
  greenOne: '#67EC85',
  greenOneAlpha: 'rgba(103, 236, 133, 0.22)',
  greenTwo: '#0B8043',
  greenFour: '#A2EE5E',
  greenFive: '#45dc67',
  whiteOne: '#FFFFFF',
  whiteTwo: '#F9F9FB',
  blueOne: '#5773FF',
  redOne: '#F96D6D',
  lightBlueOne: '#9FCDFF',
  lightBlueTwo: '#0DC1DA',
  lightBlueThree: '#70BFF4',
  yellowOne: '#F9E09A',
  orangeOne: '#FFC16D',
  ownerEvent: '#1CCEE7',
  guestOfOwnerEvent: '#0B8043',
  renterEvent: '#D50000',
  maintenanceEvent: '#F6BF26',
  bufferEvent: '#039BE5',
  groupHoldEvent: '#8E24AA',
  roomBlock: '#a3a3a3bf',
  roomBuffer: '#8fabd0bf',
  onPropertyEventTransparent: 'rgb(133 217 150 / 20%)',
  maintenanceEventTransparent: 'rgb(249 224 154 / 20%)',
  frontDeskEventTransparent: 'rgb(127 211 237 / 20%)',
  renterTransparent: 'rgb(213 1 1 / 20%)',
  ownerTransparent: 'rgb(15 193 218 / 20%)',
  guestOfOwnerTransparent: 'rgb(11 128 66 / 40%)',
  roomBlockTransparent: 'rgb(146 157 173 / 20%)',
  rateTransparent: 'rgb(0 0 0 / 5%)',
  groupHoldEventTransparent: 'rgb(142 36 170 / 20%)',
  paid: '#00bc2dbf',
  parcialPaid: '#dd9a00bf',
  notPaid: '#c80000bf',
  roomDisabled: '#a3a3a3bf',
  groupHold: '#a16cc2bf',
  calendarCurrentDay: '#cce2ff',
};

export default Colors;
