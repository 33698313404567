import React from 'react';
import { Radio } from 'semantic-ui-react';
import { createUseStyles } from 'react-jss';
import Legend from './Legend';
import IconButtons from '@/components/Buttons/IconButtons';
import Colors from '@/constants/colors';
import Breakpoints from '@/constants/breakpoints';
import { TOOLBAR_VIEWS } from '@/constants/application';

const { WEEK, MONTH } = TOOLBAR_VIEWS;

const useStyles = createUseStyles(
  {
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '1rem',
      borderRight: `1px solid ${Colors.grayTwo}`,
      borderTopRightRadius: 4,
      borderLeft: `1px solid ${Colors.grayTwo}`,
      borderTopLeftRadius: 4,
      borderTop: `1px solid ${Colors.grayTwo}`,
    },
    title: {
      margin: 0,
      fontWeight: 'normal',
    },
    actions: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      flex: 1,
    },
    content: {
      display: 'flex',
      alignItems: 'center',
      '& > *': {
        margin: '0 0.5rem',
      },
    },
    toggle: {
      display: 'inline-flex',
      justifyContent: 'end',
      flex: 1,
      '& > *:first-child': {
        marginRight: '1rem',
      },
    },
    [`@media (max-width: ${Breakpoints.xl}px)`]: {
      root: {
        flexDirection: 'column',
      },
      actions: {
        width: '100%',
      },
      content: {
        flexDirection: 'column',
        '& > *:first-child': {
          marginBottom: '0.5rem',
        },
      },
      toggle: {
        width: '100%',
      },
    },
  },
  { name: 'Toolbar' },
);

const Toolbar = ({ view, date, label, onNavigate, onView }) => {
  const classes = useStyles();
  const hideLegend = true;
  const hideWeeklySelector = true;

  const onClick = (_event, { action }) => {
    onNavigate(action, date);
  };

  return (
    <>
      <div className={classes.root}>
        <div className={classes.actions}>
          <IconButtons.Previous onClick={onClick} action="PREV" />
          <div className={classes.content}>
            <h3 className={classes.title}>{label}</h3>
          </div>
          <IconButtons.Next onClick={onClick} action="NEXT" />
        </div>
      </div>
      {hideLegend ? (
        <>
          {!hideWeeklySelector && (
            <div style={{ marginLeft: '10px' }}>
              <div className={classes.toggle}>
                <Radio
                  toggle
                  checked={view === WEEK}
                  onClick={() => onView(view === WEEK ? MONTH : WEEK)}
                />
                <small>Week View</small>
              </div>
            </div>
          )}
        </>
      ) : (
        <Legend.Events>
          {!hideWeeklySelector && (
            <div className={classes.toggle}>
              <Radio
                toggle
                checked={view === WEEK}
                onClick={() => onView(view === WEEK ? MONTH : WEEK)}
              />
              <small>Week View</small>
            </div>
          )}
        </Legend.Events>
      )}
    </>
  );
};

export default React.memo(Toolbar);
