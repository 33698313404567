import React from 'react';
import { Button, Icon } from 'semantic-ui-react';
import Colors from '@/constants/colors';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(
  {    
    circularPrev: {
      borderRadius: '10px !important',
      fontSize: '22px !important',
      padding: '7px 7px 7px 7px !important',
      paddingLeft: '5px !important',
      boxShadow: ' 0px 0px 5px 0px rgba(0,0,0,0.75); !important',
      background: '#fff !important',
      border: '1px solid #ccc !important'
    }
  },
  { name: 'IconButtons' },
);

const IconButton = React.memo(
  ({ icon, color, onClick, circular, loading, firstLabel, secondLabel, ...props }) => (
    <Button className="white" onClick={onClick} size="tiny" circular={circular} icon {...props}>
      {firstLabel}
      <Icon name={icon} style={{ color }} loading={loading} />
      {secondLabel}
    </Button>
  ),
);

const Edit = (props) => <IconButton icon="pencil" color={Colors.grayThree} {...props} />;

const Remove = (props) => <IconButton icon="trash" color={Colors.redOne} {...props} />;

const Add = (props) => <IconButton icon="add" color={Colors.greenOne} {...props} />;

const Close = (props) => <IconButton icon="close" color={Colors.grayThree} {...props} />;

const Next = (props) => (
  <IconButton icon="chevron right" color={Colors.grayThree} circular {...props} />
);

const RectangularNext = (props) => (
  <IconButton
    labelPosition="right"
    icon="right arrow"
    color={Colors.grayThree}
    firstLabel="Next"
    {...props}
  />
);

const RectangularPrevious = (props) => (
  <IconButton
    labelPosition="left"
    icon="left arrow"
    color={Colors.grayThree}
    secondLabel="Prev"
    {...props}
  />
);

const CircularPrevious = (props) => {
  const classes = useStyles();
  return (
    <IconButton className={classes.circularPrev} icon="chevron left" color={Colors.grayThree} circular {...props} />
  )
};

const CircularNext = (props) => {
  const classes = useStyles();
  return (
    <IconButton className={classes.circularPrev} icon="chevron right" color={Colors.grayThree} circular {...props} />
  )
};

const Previous = (props) => (
  <IconButton icon="chevron left" color={Colors.grayThree} circular {...props} />
);

const Refresh = (props) => <IconButton icon="refresh" color={Colors.blackOne} {...props} />;

const IconButtons = {
  Edit,
  Remove,
  Add,
  Close,
  Next,
  Previous,
  Refresh,
  RectangularNext,
  RectangularPrevious,
  CircularPrevious,
  CircularNext
};

export default IconButtons;
