import React from 'react';
import { Image } from 'semantic-ui-react';
import { createUseStyles } from 'react-jss';
import MenuItem from './MenuItem';
import Colors from '@/constants/colors';
import Breakpoints from '@/constants/breakpoints';
import { USER_ROUTES } from '@/constants/application.js';

const useStyles = createUseStyles(
  {
    root: {
      height: '100%',
      padding: '3em 1rem',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: Colors.whiteTwo,
      color: Colors.grayOne,
    },
    image: {
      width: '30px',
      marginBottom: '80px',
    },
    [`@media (max-width: ${Breakpoints.lg}px)`]: {
      image: {
        marginBottom: '2rem',
      },
    },
  },
  { name: 'Menu' },
);

const Menu = ({ onClick }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Image src="/assets/wave.svg" className={classes.image} />
      <MenuItem
        image="/assets/calendar.svg"
        text="Rooms"
        link={USER_ROUTES.root}
        onClick={onClick}
      />
      <MenuItem
        image="/assets/calendar.svg"
        text="Room"
        link={USER_ROUTES.roomCalendar}
        onClick={onClick}
      />
    </div>
  );
};

export default React.memo(Menu);
