import React, { useState } from 'react';
import { Sidebar, Menu } from 'semantic-ui-react';
import { createUseStyles } from 'react-jss';
import WaveMenu from './Menu/Menu';

const useStyles = createUseStyles(
  {
    root: {
      minHeight: '100vh',
      border: 'none',
      borderRadius: 0,
    },
    pusher: {
      height: '100%',
    },
  },
  { name: 'OwnerPanelLayout' },
);

const SidebarContainer = ({ children }) => {
  const classes = useStyles();
  const [visible, setVisible] = useState(false);

  const hide = () => setVisible(false);
  const show = () => setVisible(true);

  return (
    <Sidebar.Pushable className={classes.root}>
      <Sidebar
        as={Menu}
        animation="overlay"
        icon="labeled"
        onHide={hide}
        visible={visible}
        vertical
      >
        <WaveMenu onClick={hide} />
      </Sidebar>
      <Sidebar.Pusher className={classes.pusher} dimmed={visible}>
        {children({ show })}
      </Sidebar.Pusher>
    </Sidebar.Pushable>
  );
};

export default React.memo(SidebarContainer);
