import React, { useState, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { Dimmer, Loader, Form } from 'semantic-ui-react';
import DatepickerField from '@/components/Fields/DatepickerField';
import usePrepareCalendar from '@/hooks/usePrepareCalendar';
import IconButtons from '@/components/Buttons/IconButtons';
import RoomDropdown from '@/components/Dropdowns/RoomDropdown';
import Calendar from '@/components/Calendar/Calendar';
import { sessionSelector } from '@/store/selector';
import DateHelper from '@/helpers/dateHelper';
import QuickViewModal from '@/components/Modal/QuickViewModal';
import { EVENT_TYPES, RESERVATION_TYPES } from '@/constants/application';
import DateUtils from '@/helpers/dateHelper';
import Breakpoints from '@/constants/breakpoints';

const useStyles = createUseStyles(
  {
    content: {
      display: 'flex',
      maxWidth: '100vw',
      flexDirection: 'column',
      flex: 1,
      paddingTop: '2rem',
      paddingLeft: '2rem',
      paddingRight: '2rem',
    },
    datePickerMobile: {
      [`@media (max-width: ${Breakpoints.md}px)`]: {
        '&': {
          marginTop: '1rem !important',
        },
      },
    },
  },
  { name: 'RoomCalendarView' },
);

const initialState = { date: new Date(), roomId: null };

const RoomCalendarView = () => {
  const classes = useStyles();
  const [state, setState] = useState(initialState);
  const [{ loading, year, events }] = usePrepareCalendar(state.date, state.roomId);
  const [openQuickView, setOpenQuickView] = useState(false);
  const [eventSelected, setEventSelected] = useState(null);
  const [defaultDate, setDefaultDate] = useState(state.date);
  const session = sessionSelector();
  const user = session.user;

  const refreshCalendar = () => {
    setState({ ...state, date: DateHelper.toDate(state.date) });
  };

  const onChangeRoomSelected = (roomId) => {
    setState({ ...state, roomId: roomId });
  };

  const onChangeDate = (newDate) => {
    setState({ ...state, date: newDate });
  };

  const setSelectedDate = (_event, { value }) => {
    if (DateUtils.isAnotherYear(value, year)) {
      onChangeDate(value);
    }
    setDefaultDate(value);
  };

  useEffect(() => {
    setState(initialState);
  }, [user?.selectedProperty]);

  // const { date: defaultDate } = state;

  /* const eventTest = {
    id: 34252075,
    bgColor: '#00bc2dbf',
    customer: {
      id: 34252069,
      firstName: 'PAUL',
      lastName: 'TELESCA',
      mobileStandarized: '+17326725574',
      email: 'PAUL.TELESCA@YAHOO.COM',
    },
  }; */

  const onSelectCalendarEventAction = (event) => {
    if (event.type == EVENT_TYPES.RESERVATION && event.reservationType == RESERVATION_TYPES.RENT) {
      setOpenQuickView(true);
      setEventSelected({
        id: event.id,
        customer: { firstName: '', lastName: '' },
        bgColor: '#00bc2dbf',
      });
    }
  };

  return (
    <div className={classes.content}>
      <Form>
        <Form.Group>
          <Form.Field>
            <RoomDropdown onRoomChange={onChangeRoomSelected} />
          </Form.Field>
          <DatepickerField
            id="selectedDate"
            label=""
            placeholder="Select Date"
            value={defaultDate}
            onChange={setSelectedDate}
            iconPosition="left"
            className={classes.datePickerMobile}
          />
          <Form.Field>
            <IconButtons.Refresh
              title="Refresh calendar"
              onClick={refreshCalendar}
              loading={loading}
              size="medium"
              className={classes.datePickerMobile}
            />
          </Form.Field>
        </Form.Group>
      </Form>
      <Dimmer.Dimmable dimmed={loading}>
        <Dimmer active={loading} inverted>
          <Loader inverted content="Loading calendar..." />
        </Dimmer>
        {state?.roomId && state?.date && (
          <Calendar
            year={year}
            events={events}
            onChange={onChangeDate}
            defaultDate={defaultDate}
            onSelectEventAction={onSelectCalendarEventAction}
          />
        )}
      </Dimmer.Dimmable>
      <QuickViewModal
        open={openQuickView}
        onClose={() => {
          setOpenQuickView(false);
        }}
        event={eventSelected}
      ></QuickViewModal>
    </div>
  );
};

export default React.memo(RoomCalendarView);
