import React, { useState, useRef } from 'react';
import { createUseStyles } from 'react-jss';
import { Button } from 'semantic-ui-react';
import Breakpoints from '@/constants/breakpoints';
import { Dimmer, Loader } from 'semantic-ui-react';
import WaveScheduler from '@/components/Calendar/WaveScheduler';
import usePrepareScheduler from '@/hooks/usePrepareScheduler';
import { CALENDAR_CONF } from '@/constants/calendar';
import IconButtons from '@/components/Buttons/IconButtons';
import DatepickerField from '@/components/Fields/DatepickerField';
import { Form } from 'semantic-ui-react';
import DateHelper from '@/helpers/dateHelper';
import classnames from 'classnames';
import { Media } from '../media';
import _ from 'lodash';
import QuickViewModal from '../components/Modal/QuickViewModal';

const useStyles = createUseStyles(
  {    
    content: {
      display: 'flex',
      maxWidth: '100vw',
      flexDirection: 'column',
      flex: 0,
      paddingTop: '2rem',
      paddingLeft: '2rem',
      paddingRight: '2rem',
    },    
    formClass: {
      alignItems: 'center',
    },
    todayButton: {
      borderRadius: '10px !important',
      fontSize: '18px !important',
      padding: '9px 15px 9px 15px !important',
      boxShadow: ' 0px 0px 5px 0px rgba(0,0,0,0.75); !important',
      background: '#fff !important',
      border: '1px solid #ccc !important'
    },
    previousMobile: { marginRight: '0.5em !important' },
    prevNextField: {
      [`@media (max-width: ${Breakpoints.md}px)`]: {
        paddingRight: '0 !important',
      },
      [`@media (min-width: 800px)`]: {
        width: '87.5% !important',
      },
    },
  },
  { name: 'RoomsGridView' },
);

const RoomsGridView = () => {
  const classes = useStyles();
  const calendarConfiguration = CALENDAR_CONF.customMonth;
  const [defaultDate, setDefaultDate] = useState(calendarConfiguration.dateFormat(new Date()));
  const [{ setLoading, loading, events, resources }] = usePrepareScheduler(
    defaultDate,
    calendarConfiguration.daysShown,
  );
  const [openQuickView, setOpenQuickView] = useState(false);
  const [eventSelected, setEventSelected] = useState(null);
  const prevWidth = useRef(document.body.clientWidth);

  calendarConfiguration.selectedDate = defaultDate;

  const refreshScheduler = () => {
    updateScheduler(new Date(defaultDate));
  };

  const setSelectedDate = (_event, { value }) => {
    if (value && value != defaultDate) {
      updateScheduler(value);
    }
  };

  const onClickToday = () => {
    updateScheduler(DateHelper.today());
  };

  const onClickNext = () => {
    var nextDate = DateHelper.toDate(
      DateHelper.addDays(defaultDate, calendarConfiguration.daysShown),
    );
    updateScheduler(nextDate);
  };

  const onClickPrev = () => {
    var prevDate = DateHelper.toDate(
      DateHelper.subtractDays(defaultDate, calendarConfiguration.daysShown),
    );
    updateScheduler(prevDate);
  };

  const updateScheduler = (selectedDate) => {
    if (loading) return;
    setDefaultDate(new Date(selectedDate));
  };

  const debouncedHandleResize = _.debounce(
    () => {
      let currWidth = document.body.clientWidth;
      if (currWidth !== prevWidth.current) {
        prevWidth.current = currWidth;
        refreshScheduler();
      }
    },
    1000,
    {
      leading: false,
      maxWait: 1,
    },
  );

  React.useEffect(() => {
    window.addEventListener('resize', debouncedHandleResize);

    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  });

  calendarConfiguration.onEventClicked = (event) => {
    if (event.customer !== undefined){
      setOpenQuickView(true);
      setEventSelected(event);
    }
  };

  return (
    <div className={classes.content}>
      <Form>
        <Form.Group className={classes.formClass}>
          <DatepickerField
            id="selectedDate"
            label=""
            placeholder="Select Date"
            value={defaultDate}
            onChange={setSelectedDate}
            iconPosition="left"
          />
          <Form.Field>
            <IconButtons.Refresh
              title="Refresh grid"
              onClick={refreshScheduler}
              loading={loading}
              size="medium"
            />
          </Form.Field>
          <Form.Field className={classes.prevNextField}>
            <Media lessThan="lg">
              <IconButtons.Previous
                title="Previous"
                onClick={onClickPrev}
                size="medium"
                circular={false}
                className={classnames('white', classes.previousMobile)}
              />
              <IconButtons.Next title="Next" onClick={onClickNext} size="medium" circular={false} />
            </Media>
            <Media greaterThanOrEqual="lg">
              <IconButtons.CircularNext
                title="Next"
                onClick={onClickNext}
                size="medium"
                floated="right"
              />
              <Button
                title="Today"
                size="medium"
                floated="right"
                className={classes.todayButton}
                onClick={onClickToday}
              >Today</Button>
              <IconButtons.CircularPrevious
                title="Previous"
                onClick={onClickPrev}
                size="medium"
                floated="right"
              />
            </Media>
          </Form.Field>
        </Form.Group>
      </Form>
      <Dimmer.Dimmable dimmed={loading}>
        <Dimmer active={loading} inverted>
          <Loader inverted content="Loading calendar..." />
        </Dimmer>

        <WaveScheduler
          events={events}
          resources={resources}
          calendarConfiguration={calendarConfiguration}
          setLoading={setLoading}
        />
      </Dimmer.Dimmable>
      <QuickViewModal open={openQuickView} onClose={() => {setOpenQuickView(false);}} event={eventSelected}></QuickViewModal>
    </div>
  );
};

export default RoomsGridView;
