import { useState, useEffect } from 'react';
import { prepareScheduler } from '@/api/sessionAPI';
import { sessionSelector } from '@/store/selector';
import { RESERVATION_PAYMENT_STATUS, RESERVATION_ROOM_TYPE } from '@/constants/application.js';
import Colors from '@/constants/colors';
import isMobile from '@/helpers/mobileHelper';
// import { DATE_FORMAT } from '@/components/Calendar/react-big-scheduler-wave';
import { DATE_FORMAT } from 'react-big-scheduler-stch';
import moment from '@/helpers/moment';

const makeRoomsTitle = (roomNumber, gridName) => {
  return roomNumber + ' - ' + gridName;
};

const makeRoomsTitleForMobile = (roomNumber, gridName) => {
  return roomNumber + ' - ' + gridName;
};

const makeReservationRoomTitle = (id, customer) => {
  let reservationName = '';
  reservationName = customer.lastName;
  reservationName += ', ' + customer.firstName;
  return reservationName + ' [' + id + ']';
};

const makeReservationRoomColor = (paymentStatus) => {
  let color = '';
  switch (paymentStatus) {
    case 'paid':
      color = RESERVATION_PAYMENT_STATUS.paid.color;
      break;
    case 'parcial-paid':
      color = RESERVATION_PAYMENT_STATUS.parcialPaid.color;
      break;
    case 'not-paid':
      color = RESERVATION_PAYMENT_STATUS.notPaid.color;
      break;
    case 'room-disabled':
      color = RESERVATION_PAYMENT_STATUS.roomDisabled.color;
      break;
    case 'group-hold':
      color = RESERVATION_PAYMENT_STATUS.groupHold.color;
      break;
  }
  return color;
};

const setReservationRoomIcon = (reservationRoomType) => {
  let icon = '';
  switch (reservationRoomType) {
    case 'reservation-group':
      icon = RESERVATION_ROOM_TYPE.reservationGroup;
      break;
    case 'owner-reservation':
      icon = RESERVATION_ROOM_TYPE.onwerReservation;
      break;
    case 'do-not-move':
      icon = RESERVATION_ROOM_TYPE.doNotMove;
      break;
    case 'room-requested':
      icon = RESERVATION_ROOM_TYPE.roomRequested;
      break;
    case 'multiple-reservation-room':
      icon = RESERVATION_ROOM_TYPE.multipleReservationRooms;
      break;
    default:
      icon = undefined;
      break;
  }
  return icon;
};

const processSchedulerResponse = ({ data }) =>
  new Promise((resolve) => {
    const {
      reservationRoomList = [],
      roomBlockDataList = [],
      roomBufferDataList = [],
      roomList,
    } = data;

    const rooms = roomList.map(({ id, roomNumber, roomType: roomType, statusCode }) => {
      return {
        id: id,
        name: isMobile.matches
          ? makeRoomsTitleForMobile(roomNumber, roomType.gridName)
          : makeRoomsTitle(roomNumber, roomType.gridName, statusCode),
      };
    });

    const reservationRooms = reservationRoomList.map(
      ({
        id,
        checkInAsString,
        checkOutAsString,
        reservation,
        room,
        inHouse,
        reservationRoomType,
      }) => {
        return {
          id: id,
          title: makeReservationRoomTitle(reservation.id, reservation.customer),
          resourceId: room.id,
          start: checkInAsString,
          end: checkOutAsString,
          movable: false,
          resizable: false,
          bgColor: makeReservationRoomColor(reservation.paymentStatus),
          inHouse: inHouse,
          leftIcon: setReservationRoomIcon(reservationRoomType),
          customer: reservation.customer,
          balance: reservation.balance,
          totalPaid: reservation.totalPaid,
          paymentStatus: reservation.paymentStatus,
        };
      },
    );

    const roomBlocks = roomBlockDataList.map(({ id, roomId, reason, startDate, endDate }) => {
      return {
        id: id,
        title: reason,
        resourceId: roomId,
        start: startDate,
        end: moment(endDate).add(1, 'd').format(DATE_FORMAT),
        movable: false,
        resizable: false,
        bgColor: Colors.roomBlock,
      };
    });

    const roomBuffers = roomBufferDataList.map(({ id, roomId, reason, startDate, endDate }) => {
      return {
        id: id,
        title: reason,
        resourceId: roomId,
        start: startDate,
        end: moment(endDate).add(1, 'd').format(DATE_FORMAT),
        movable: false,
        resizable: false,
        bgColor: Colors.roomBuffer,
      };
    });

    const events = [...reservationRooms, ...roomBlocks, ...roomBuffers];
    const resources = rooms;
    resolve({ events, resources });
  });

const usePrepareScheduler = (startDate, daysShown) => {
  const [state, setState] = useState({ events: [], resources: [] });
  const session = sessionSelector();
  const user = session.user;
  const [loading, setLoading] = useState(true);

  const refetch = () => {
    setLoading(true);
    if (!user?.selectedProperty) {
      return;
    }

    const data = {
      startDate: startDate,
      propertyId: user.selectedProperty,
      daysShown: daysShown + 1,
    };

    prepareScheduler(data)
      .then(processSchedulerResponse)
      .then((res) => {
        setState(res);
        //setLoading(false);
      });
  };

  useEffect(refetch, [startDate, user?.selectedProperty]);

  return [{ setLoading, loading, ...state }];
};

export default usePrepareScheduler;
