import { useState, useEffect } from 'react';
import { prepareCalendar } from '@/api/sessionAPI';
import { EVENT_TYPES, RESERVATION_TYPES } from '@/constants/application';
import DateUtils, { FORMAT } from '@/helpers/dateHelper';
import moment from '@/helpers/moment';
import { sessionSelector } from '@/store/selector';

const groupDailyEvents = (events) =>
  Object.values(
    events.flat().reduce((obj, { date, type, ...props }) => {
      obj[date] = obj[date] || {
        type: EVENT_TYPES.DAILY,
        allDay: true,
        start: date,
        end: date,
        activities: [],
        slots: date ? [DateUtils.formatDate(date, FORMAT)] : [],
      };

      obj[date].activities.push({ date, type, ...props });
      return obj;
    }, {}),
  );

const makeReservationTitle = ({
  prefix,
  firstName,
  lastName,
  startDate,
  endDate,
  displayHyphen,
}) => {
  const hyphen = displayHyphen ? `-` : ``;
  let result = prefix || '';

  if (firstName && lastName) {
    result = `${firstName} ${lastName}`;
  }

  return `${result} ${hyphen} ${DateUtils.getNumberOfNights(startDate, endDate)} Nights`;
};

const processCalendarResponse = ({ data }) =>
  new Promise((resolve) => {
    const {
      reservationList = [],
      requestList = [],
      propertyEventList = [],
      maintenanceEventList = [],
      frontDeskList = [],
      roomBlockDataList = [],
      roomBufferDataList = [],
      rateList = [],
    } = data;

    const reservations = reservationList.map(
      ({
        id,
        reservationId,
        startDate,
        endDate,
        ownerReservation,
        guestOfOwnerReservation,
        groupHold,
        firstName,
        lastName,
        ...props
      }) => {
        let reservationType = RESERVATION_TYPES.HOLD;
        let prefix = '';

        if (!groupHold) {
          reservationType = RESERVATION_TYPES.RENT;

          if (ownerReservation) {
            reservationType = RESERVATION_TYPES.OWNER;

            if (guestOfOwnerReservation) {
              reservationType = RESERVATION_TYPES.GUEST_OF_OWNER;
            }
          }
        }

        if (reservationType === RESERVATION_TYPES.RENT) {
          prefix = 'Renter';
        } else if (reservationType === RESERVATION_TYPES.HOLD) {
          prefix = 'Hold';
        }

        return {
          id: reservationId,
          reservationRoomId: id,
          title: makeReservationTitle({
            prefix,
            reservationType,
            firstName,
            lastName,
            startDate,
            endDate,
            displayHyphen: true,
          }),
          reservationTitle: makeReservationTitle({
            prefix,
            reservationType,
            firstName,
            lastName,
            startDate,
            endDate,
            displayHyphen: false,
          }),
          type: EVENT_TYPES.RESERVATION,
          reservationType,
          allDay: true,
          start: startDate,
          end: endDate,
          slots: DateUtils.getDaysInBetween(startDate, endDate),
          ownerReservation,
          guestOfOwnerReservation,
          user: { firstName, lastName },
          ...props,
        };
      },
    );

    const roomBlocks = roomBlockDataList.map(
      ({ id, roomId, startDate, endDate, reason, ...props }) => {
        const reservationType = RESERVATION_TYPES.ROOM_BLOCK;
        return {
          id,
          reservationRoomId: roomId,
          title: makeReservationTitle({
            prefix: 'Room block',
            reservationType,
            startDate,
            endDate,
            displayHyphen: true,
          }),
          reservationTitle: makeReservationTitle({
            prefix: 'Room block',
            reservationType,
            startDate,
            endDate,
            displayHyphen: false,
          }),
          description: reason,
          type: EVENT_TYPES.RESERVATION,
          reservationType,
          allDay: true,
          start: startDate,
          end: endDate,
          slots: DateUtils.getDaysInBetween(startDate, endDate),
          ...props,
        };
      },
    );

    const roomBuffers = roomBufferDataList.map(
      ({ id, roomId, startDate, endDate, reason, ...props }) => {
        const reservationType = RESERVATION_TYPES.ROOM_BUFFER;
        return {
          id,
          reservationRoomId: roomId,
          title: makeReservationTitle({
            prefix: 'Room Buffer',
            reservationType,
            startDate,
            endDate,
            displayHyphen: true,
          }),
          reservationTitle: makeReservationTitle({
            prefix: 'Room Buffer',
            reservationType,
            startDate,
            endDate,
            displayHyphen: false,
          }),
          description: reason,
          type: EVENT_TYPES.RESERVATION,
          reservationType,
          allDay: true,
          start: startDate,
          end: endDate,
          slots: DateUtils.getDaysInBetween(startDate, endDate),
          ...props,
        };
      },
    );

    const rates = rateList.map(({ startDate, rate, ...props }) => {
      const reservationType = RESERVATION_TYPES.RATES;
      return {
        rate,
        slots: DateUtils.getDaysInBetween(startDate, startDate),
        start: startDate,
        end: startDate,
        type: EVENT_TYPES.RATES,
        reservationType,
        ...props,
      };
    });

    const requests = requestList.map((event) => ({
      ...event,
      type: EVENT_TYPES.REQUEST,
    }));

    const propertyEvents = propertyEventList.map((event) => ({
      ...event,
      type: EVENT_TYPES.ON_PROPERTY,
    }));

    const maintenanceEvents = maintenanceEventList.map((event) => ({
      ...event,
      type: EVENT_TYPES.MAINTENANCE,
    }));

    const frontDeskNotifications = frontDeskList.map((event) => ({
      ...event,
      type: EVENT_TYPES.FRONT_DESK_NOTIFICATION,
    }));

    const dailies = groupDailyEvents([
      requests,
      propertyEvents,
      maintenanceEvents,
      frontDeskNotifications,
    ]);

    const events = [...reservations, ...roomBlocks, ...roomBuffers, ...dailies, ...rates];
    resolve({ events });
  });

const usePrepareScheduler = (startDate, selectedRoomId) => {
  const [state, setState] = useState({ events: [] });
  const [loading, setLoading] = useState(false);
  const session = sessionSelector();
  const user = session.user;

  const date = moment(startDate);
  const year = date.format('YYYY');

  const refetch = () => {
    if (!selectedRoomId || !startDate) {
      return;
    }
    setLoading(true);

    const data = {
      startDate: date.startOf('year').format(FORMAT),
      endDate: date.endOf('year').format(FORMAT),
      roomIdList: [selectedRoomId],
    };

    prepareCalendar(data)
      .then(processCalendarResponse)
      .then((res) => {
        setState(res);
        setLoading(false);
      });
  };

  useEffect(refetch, [startDate, selectedRoomId]);
  useEffect(() => {
    selectedRoomId = null;
    refetch();
  }, [user?.selectedProperty]);

  return [{ loading, year, ...state }];
};

export default usePrepareScheduler;
