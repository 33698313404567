import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  token: null,
  user: null,
  userPropertyList: [],
};

export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setSession: (state, data) => {
      state.token = data.payload.token;
      state.user = {};
      state.user.firstName = data.payload.firstName;
      state.user.lastName = data.payload.lastName;
      state.user.email = data.payload.email;
      state.user.selectedProperty = data.payload.userDefaultProperty;
    },
    cleanSession: (state) => {
      state.token = null;
      state.user = null;
      state.userPropertyList = [];
    },
    prepareApp: (state, data) => {
      const { userPropertyList = [] } = data.payload;
      state.userPropertyList = userPropertyList;
    },
    selectProperty: (state, data) => {
      const selectedPropertyId = data.payload;
      state.user.selectedProperty = selectedPropertyId;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setSession, cleanSession, prepareApp, selectProperty } = sessionSlice.actions;

export default sessionSlice.reducer;
