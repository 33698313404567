import Colors from '@/constants/colors';
import moment from '@/helpers/moment';
import { FORMAT } from '@/helpers/dateHelper';
// import { SchedulerData, ViewType, DATE_FORMAT } from './react-big-scheduler-wave';
import { ViewType, DATE_FORMAT, CellUnit } from 'react-big-scheduler-stch';
import isMobile, { isLessThanLg } from '@/helpers/mobileHelper';

/* Configuration */

function getConfiguration() {
  let monthlyConfiguration = {
    //besidesWidth: isMobile.matches ? 40 : 220,
    schedulerWidth: '100%',
    /*schedulerMaxHeight: isMobile.matches
      ? window.screen.availHeight - 245
      : window.screen.availHeight - 350,*/
    schedulerMaxHeight: window.scrollY + window.innerHeight - 190,
    schedulerContentHeight: '100%',
    resourceName: '',
    recurringEventsEnabled: false,
    creatable: false,
    checkConflict: false,
    eventItemHeight: 29,
    eventItemLineHeight: 32,
    scrollToSpecialDayjsEnabled: false,
    views: [],
    customCellDays: 31,
    monthCellDays: 30,
    customCellWidth: (document.body.clientWidth - 350) / 31,
    monthCellWidth: (document.body.clientWidth - 350) / 30,
    calendarPopoverEnabled: false,
    headerEnabled: false,
  };

  if (isLessThanLg.matches) {
    monthlyConfiguration.customCellWidth = (document.body.clientWidth - 125) / 31;
    monthlyConfiguration.monthCellWidth = (document.body.clientWidth - 125) / 30;
    monthlyConfiguration.monthResourceTableWidth = 70;
    monthlyConfiguration.customResourceTableWidth = 70;
  }

  if (isMobile.matches) {
    monthlyConfiguration.customCellDays = 7;
    monthlyConfiguration.monthCellDays = 7;
    monthlyConfiguration.schedulerMaxHeight = window.scrollY + window.innerHeight - 160;
    monthlyConfiguration.monthResourceTableWidth = 70;
    monthlyConfiguration.customResourceTableWidth = 70;
    monthlyConfiguration.monthCellWidth = (document.body.clientWidth - 125) / 7;
    monthlyConfiguration.customCellWidth = (document.body.clientWidth - 125) / 7;
    monthlyConfiguration.besidesWidth = 50;
  }

  return monthlyConfiguration;
}

function getCustomMonthConfiguration() {
  let monthlyConfiguration = getConfiguration();
  this.cellWidth = monthlyConfiguration.customCellWidth;
  this.totalCellWidth = monthlyConfiguration.customCellWidth * monthlyConfiguration.customCellDays;
  return monthlyConfiguration;
}

function getMonthConfiguration() {
  let monthlyConfiguration = getConfiguration();
  this.cellWidth = monthlyConfiguration.monthCellWidth;
  this.totalCellWidth = monthlyConfiguration.monthCellWidth * monthlyConfiguration.monthCellDays;
  return monthlyConfiguration;
}

/* Behavior */

const getNonAgendaViewBodyCellBgColorFunc = (schedulerData, slotId, header) => {
  let datetime = schedulerData.localeDayjs(header.time);
  let isCurrentDate = false;
  let isWeekendDate = false;

  if (schedulerData.viewType === ViewType.Day) {
    isCurrentDate = datetime.isSame(new Date(), 'hour');
  } else {
    isCurrentDate = datetime.isSame(new Date(), 'day');
    isWeekendDate =
      datetime.isSame(datetime.weekday(0), 'day') || datetime.isSame(datetime.weekday(6), 'day');
  }

  if (isCurrentDate) {
    return Colors.calendarCurrentDay;
  }

  if (isWeekendDate) {
    return Colors.graySix;
  }
};

const getCustomDate = (schedulerData, num, date = undefined) => {
  const { viewType } = schedulerData;
  let selectDate = schedulerData.startDate;
  if (date != undefined) selectDate = date;

  let startDate =
      num === 0
        ? selectDate
        : schedulerData
            .localeDayjs(selectDate)
            .add(2 * num, 'days')
            .format(DATE_FORMAT),
    endDate = schedulerData.localeDayjs(startDate).add(1, 'days').format(DATE_FORMAT),
    cellUnit = CellUnit.Hour;
  if (viewType === ViewType.Custom) {
    let firstDate = schedulerData.localeDayjs(selectDate).format(DATE_FORMAT);
    startDate =
      num === 0
        ? firstDate
        : schedulerData
            .localeDayjs(firstDate)
            .add(num * 30, 'days')
            .format(DATE_FORMAT);
    endDate = schedulerData.localeDayjs(firstDate).add(30, 'days').format(DATE_FORMAT);
    cellUnit = CellUnit.Day;
  }

  return {
    startDate,
    endDate,
    cellUnit,
  };
};

const isNonWorkingTime = (schedulerData, time) => {
  const { localeDayjs } = schedulerData;
  if (schedulerData.cellUnit === CellUnit.Hour) {
    let hour = localeDayjs(time).hour();
    if (hour < 1) return true;
  } else {
    let dayOfWeek = localeDayjs(time).weekday();
    if (dayOfWeek === 0 || dayOfWeek === 6) return true;
  }

  return false;
};

function getMonthlyBehavior() {
  let monthlyBehavior = {
    getNonAgendaViewBodyCellBgColorFunc: getNonAgendaViewBodyCellBgColorFunc,
  };

  return monthlyBehavior;
}

function getCustomMonthlyBehavior() {
  let customMonthlyBehavior = {
    getNonAgendaViewBodyCellBgColorFunc: getNonAgendaViewBodyCellBgColorFunc,
    getCustomDateFunc: getCustomDate,
    isNonWorkingTimeFunc: isNonWorkingTime,
  };

  return customMonthlyBehavior;
}

/* Monthly events */
function getPrevClickDate(currentDate) {
  return moment(currentDate).add(-1, 'M').format(DATE_FORMAT);
}

function getNextClickDate(currentDate) {
  return moment(currentDate).add(1, 'M').format(DATE_FORMAT);
}

function getSelectClickDate(currentDate, newDate, onChange) {
  let currentMonth = moment(currentDate).startOf('month').format(DATE_FORMAT);
  let selectedMonth = moment(newDate).startOf('month').format(DATE_FORMAT);
  if (currentMonth != selectedMonth) {
    onChange(newDate);
  }
}

/* Quaarter events */

function getNextClickDateForQuarter(currentDate) {
  return moment(currentDate).startOf('quarter').add(3, 'M').format(DATE_FORMAT);
}

function getPrevClickDateForQuarter(currentDate) {
  return moment(currentDate).startOf('quarter').add(-3, 'M').format(DATE_FORMAT);
}

function getSelectClickDateForQuarter(currentDate, newDate, onChange) {
  let currentQuarter = moment(currentDate).startOf('quarter').format(DATE_FORMAT);
  let selectedQuarter = moment(newDate).startOf('quarter').format(DATE_FORMAT);
  if (currentQuarter != selectedQuarter) {
    onChange(newDate);
  }
}

/* Year events */

function getNextClickDateForYear(currentDate) {
  return moment(currentDate).startOf('year').add(1, 'y').format(DATE_FORMAT);
}

function getPrevClickDateForYear(currentDate) {
  return moment(currentDate).startOf('year').add(-1, 'y').format(DATE_FORMAT);
}

function getSelectClickDateForYear(currentDate, newDate, onChange) {
  let currentQuarter = moment(currentDate).startOf('year').format(DATE_FORMAT);
  let selectedQuarter = moment(newDate).startOf('year').format(DATE_FORMAT);
  if (currentQuarter != selectedQuarter) {
    onChange(newDate);
  }
}

/* Custom events */

function getSelectClickDateForCustomMonth(currentDate, newDate, onChange) {
  onChange(newDate);
}

export const CALENDAR_CONF = {
  month: {
    dateFormat: (currentDate) => {
      return moment(currentDate).startOf('month').format(FORMAT);
    },
    daysShown: 31,
    view: ViewType.Month,
    configuration: getMonthConfiguration,
    behaviors: getMonthlyBehavior,
    prevClickDate: getPrevClickDate,
    nextClickDate: getNextClickDate,
    selectClickDate: getSelectClickDate,
  },
  customMonth: {
    dateFormat: (currentDate) => {
      return moment(currentDate).add(-2, 'd').toDate();
    },
    daysShown: 31,
    view: ViewType.Custom,
    configuration: getCustomMonthConfiguration,
    behaviors: getCustomMonthlyBehavior,
    prevClickDate: getPrevClickDate,
    nextClickDate: getNextClickDate,
    selectClickDate: getSelectClickDateForCustomMonth,
  },
  quarter: {
    dateFormat: (currentDate) => {
      return moment(currentDate).startOf('quarter').format(FORMAT);
    },
    daysShown: 96,
    view: ViewType.Quarter,
    configuration: getCustomMonthConfiguration,
    behaviors: getMonthlyBehavior,
    prevClickDate: getPrevClickDateForQuarter,
    nextClickDate: getNextClickDateForQuarter,
    selectClickDate: getSelectClickDateForQuarter,
  },
  year: {
    dateFormat: (currentDate) => {
      return moment(currentDate).startOf('year').format(FORMAT);
    },
    daysShown: 365,
    view: ViewType.Year,
    configuration: getCustomMonthConfiguration,
    behaviors: getMonthlyBehavior,
    prevClickDate: getPrevClickDateForYear,
    nextClickDate: getNextClickDateForYear,
    selectClickDate: getSelectClickDateForYear,
  },
};

export const getConfigurationFromViewType = (viewType) => {
  let conf = undefined;
  switch (viewType) {
    case ViewType.Quarter:
      conf = CALENDAR_CONF.quarter;
      break;
    case ViewType.Year:
      conf = CALENDAR_CONF.year;
      break;
    default:
      conf = CALENDAR_CONF.customMonth;
      break;
  }
  return conf;
};
