import React from 'react';
import { Image } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import Colors from '@/constants/colors';
import Breakpoints from '@/constants/breakpoints';

const active = {
  color: Colors.blackOne,
  backgroundColor: Colors.greenOne,
};

const useStyles = createUseStyles(
  {
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      height: '96px',
      width: '92px',
      borderRadius: '4px',
      cursor: 'pointer',
      color: Colors.grayOne,
      backgroundColor: 'transparent',
      '&:hover': {
        extend: active,
      },
      '&.active': {
        extend: active,
      },
    },
    image: {
      height: '25px',
      width: '25px',
      marginBottom: '10px',
    },
    [`@media (max-width: ${Breakpoints.lg}px)`]: {
      root: {
        height: 'auto',
        width: '100%',
        padding: '0.65rem',
        margin: '5px 0',
        display: 'inline-flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
      },
      image: {
        marginRight: '0.5rem',
        marginBottom: 0,
      },
    },
  },
  { name: 'MenuItem' },
);

const MenuItem = ({ image, text, link = '', onClick, filter = 'unset' }) => {
  const classes = useStyles();

  return (
    <NavLink className={classes.root} to={link} onClick={onClick}>
      <Image className={classes.image} src={image} style={{ filter }} />
      <div>{text}</div>
    </NavLink>
  );
};

export default React.memo(MenuItem);
