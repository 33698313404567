import React, { useEffect, useState, Children } from 'react';
// import { useDispatch } from 'react-redux';
import { createUseStyles } from 'react-jss';
import { intersection } from 'lodash';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import Event from './Event';
import Toolbar from './Toolbar';
import Legend from './Legend';
import moment from '@/helpers/moment';
import Colors from '@/constants/colors';
import { TOOLBAR_VIEWS } from '@/constants/application';
import DateUtils, { EN_US } from '@/helpers/dateHelper';
// import EVENTS_ACTIONS from '../../actions/eventsActions';
import { dateFnsLocalizer, Calendar as BigCalendar } from './react-big-calendar';

const locales = {
  'en-US': EN_US,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const useStyles = createUseStyles(
  {
    root: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: 'max-content',
    },
    calendar: {
      flex: '1 1',
      minHeight: '100vh',
      '& .rbc-show-more': {
        margin: '1px 10px',
      },
      '& .rbc-event': {
        cursor: 'default',
        padding: 0,
        backgroundColor: 'transparent !important',
        outline: 'none !important',
      },
      '& .rbc-today': {
        backgroundColor: Colors.greenOneAlpha,
        boxShadow: `inset 0 0 0 2px ${Colors.greenOne}`,
      },
      '& .rbc-time-view > .rbc-time-header': {
        flex: '1 0',
        '& > .rbc-time-header-gutter': {
          display: 'none',
        },
        '& > .rbc-time-header-content': {
          borderLeft: 'none',
        },
      },
      '& .rbc-time-view > .rbc-time-content': {
        display: 'none',
      },
      '& .rbc-row-segment': {
        /*  zIndex: '-1', */
      },
      '& .rbc-month-row': {
        cursor: 'pointer',
      },
    },
    bottomLegend: {
      borderTop: 'none',
      borderBottom: `1px solid ${Colors.grayTwo}`,
      borderBottomRightRadius: 4,
      borderBottomLeftRadius: 4,
    },
  },
  { name: 'Calendar' },
);

const findMatches = (dates, events) => {
  const slots = dates.map((date) => DateUtils.defaultFormat(date));
  return events.filter((event) => !!intersection(event.slots, slots).length);
};

const Calendar = ({ year, defaultDate, events = [], onChange, onSelectEventAction }) => {
  const classes = useStyles();
  // const dispatch = useDispatch();
  const [date, setDate] = useState(defaultDate);
  const [selectedSlot, setSelectedSlot] = useState({
    slots: [new Date()],
  });
  const hideLegend = true;

  useEffect(() => {
    const payload = findMatches([defaultDate], events);
    console.log(payload);
    // dispatch({ type: EVENTS_ACTIONS.SELECT_EVENTS, payload, selectedSlot: '' });
    setDate(defaultDate);
    return () => {
      /* dispatch({
        type: EVENTS_ACTIONS.SELECT_EVENTS,
        payload: [],
        selectedSlot: '',
      }); */
    };
  }, [events, defaultDate]);

  const onSelectSlot = (selection) => {
    const payload = findMatches(selection.slots, events);
    console.log(payload);
    /* dispatch({
      type: EVENTS_ACTIONS.SELECT_EVENTS,
      payload,
      selectedSlot: selection.slots,
    }); */
    setSelectedSlot(selection);
  };

  const onSelectEvent = (event) => {
    onSelectEventAction(event);
  };

  const onNavigate = (newDate) => {
    if (DateUtils.isAnotherYear(newDate, year)) {
      onChange(newDate);
    }

    setDate(newDate);
  };

  const endAccessor = (event) => {
    const end = moment(event.end);
    const hs = parseInt(end.toISOString(true).split('T')[1].split(':')[0], 10);
    if (hs > 0) {
      return DateUtils.subtractDay(event.end).toDate();
    }
    return event.end;
  };

  const ColoredDateCellWrapper = ({ children, value }) => {
    let customStyle = {};
    const curCellIsToday = children.props.className.includes('rbc-today');
    const curCellIsSelected =
      selectedSlot.slots[0] &&
      `${value.toDateString()}` === `${selectedSlot.slots[0].toDateString()}`;

    if (curCellIsSelected) {
      customStyle = {
        boxShadow: 'inset 0 0 0 2px #67EC85',
        backgroundColor: 'rgba(103, 236, 133, 0.22)',
      };
    } else if (curCellIsToday) {
      customStyle = {
        boxShadow: 'none',
        backgroundColor: 'rgba(255, 0, 0, 0.2)',
      };
    }

    return React.cloneElement(Children.only(children), {
      style: {
        ...children.style,
        ...customStyle,
      },
    });
  };

  return (
    <div className={classes.root}>
      <BigCalendar
        className={classes.calendar}
        localizer={localizer}
        events={events}
        views={Object.values(TOOLBAR_VIEWS)}
        date={date}
        onNavigate={onNavigate}
        components={{
          dateCellWrapper: ColoredDateCellWrapper,
          event: Event,
          toolbar: Toolbar,
        }}
        onSelectSlot={onSelectSlot}
        onSelectEvent={onSelectEvent}
        endAccessor={endAccessor}
        selectable
      />
      {!hideLegend && <Legend.Reservations className={classes.bottomLegend} />}
    </div>
  );
};

export default React.memo(Calendar);
