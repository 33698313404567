import React from 'react';
import { createUseStyles } from 'react-jss';
import classnames from 'classnames';
import { Image } from 'semantic-ui-react';
import EllipsisText from '../EllipsisText';
import Colors from '@/constants/colors';
import { EVENT_TYPES, RESERVATION_TYPES } from '@/constants/application';
import Breakpoints from '@/constants/breakpoints';

const TYPE_PROPS = {
  [EVENT_TYPES.RESERVATION]: {
    src: '/assets/calendar_check.svg',
  },
  [EVENT_TYPES.MAINTENANCE]: {
    src: '/assets/maintenance_icon.svg',
    className: 'maintenance',
  },
  [EVENT_TYPES.DAILY]: { className: 'daily' },
};

const RESERVATION_TYPES_PROPS = {
  [RESERVATION_TYPES.OWNER]: {
    className: 'reservationOwner',
  },
  [RESERVATION_TYPES.GUEST_OF_OWNER]: {
    className: 'reservationGuestOfOwner',
  },
  [RESERVATION_TYPES.RENT]: {
    className: 'reservationRent',
  },
  [RESERVATION_TYPES.HOLD]: {
    className: 'reservationGroupHold',
  },
  [RESERVATION_TYPES.UNSPECIFIED]: {
    className: 'reservationUnspecified',
  },
  [RESERVATION_TYPES.ROOM_BLOCK]: {
    className: 'roomBlock',
  },
  [RESERVATION_TYPES.ROOM_BUFFER]: {
    className: 'roomBuffer',
  },
  [RESERVATION_TYPES.RATES]: {
    className: 'rates',
  },
};

const ACTIVITY_PROPS = {
  [EVENT_TYPES.REQUEST]: { key: 'request', src: '/assets/user_icon.svg' },
  [EVENT_TYPES.ON_PROPERTY]: {
    key: 'onProperty',
    src: '/assets/calendar_star_icon.svg',
  },
  [EVENT_TYPES.FRONT_DESK_NOTIFICATION]: {
    key: 'frontDeskNotification',
    src: '/assets/bell_icon.svg',
  },
  [EVENT_TYPES.MAINTENANCE]: {
    key: 'maintenance',
    src: '/assets/maintenance_icon.svg',
  },
};

const useStyles = createUseStyles(
  {
    root: {
      display: 'flex',
      alignItems: 'center',
      borderRadius: 31,
      padding: '2px',
      margin: '3px 10px',
      cursor: 'pointer',
      '&.continuesPrior': {
        marginLeft: 0,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
      },
      '&.continuesAfter': {
        marginRight: 0,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
      },
      '&.daily': {
        backgroundColor: 'transparent',
        '& > img': {
          margin: 0,
        },
        '& > img:not(:last-child)': {
          margin: '0 8px 0 0',
        },
      },
      '&.reservationOwner': {
        backgroundColor: Colors.ownerEvent,
      },
      '&.reservationGuestOfOwner': {
        backgroundColor: Colors.guestOfOwnerEvent,
      },
      '&.reservationRent': {
        backgroundColor: Colors.renterEvent,
      },
      '&.reservationGroupHold': {
        backgroundColor: Colors.groupHoldEvent,
      },
      '&.maintenance': {
        backgroundColor: Colors.yellowOne,
      },
      '&.reservationUnspecified': {
        textColor: Colors.whiteOne,
        backgroundColor: Colors.guestOfOwnerEvent,
      },
      '&.roomBlock': {
        textColor: Colors.whiteOne,
        backgroundColor: Colors.roomBlock,
      },
      '&.roomBuffer': {
        textColor: Colors.whiteOne,
        backgroundColor: Colors.roomBuffer,
      },
      '&.rates': {
        color: Colors.blackOne,
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        width: 'fit-content',
        cursor: 'default',
      },
      [`@media (max-width: ${Breakpoints.md}px)`]: {
        '&.rates': {
          color: Colors.blackOne,
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto',
          width: 'fit-content',
          cursor: 'default',
          fontSize: '7px',
        },
      },
    },
    image: {
      margin: 'auto 8px auto 5px',
    },
    title: {
      color: Colors.whiteOne,
      fontSize: 11,
      textTransform: 'capitalize',
    },
  },
  { name: 'Event' },
);

const Event = ({ continuesPrior, continuesAfter, event }) => {
  const classes = useStyles();

  const { title, type, reservationType, activities = [] } = event;
  const typeProps = TYPE_PROPS[type] || {};
  const reservationTypeProps = RESERVATION_TYPES_PROPS[reservationType] || {};
  const { src, className } = { ...typeProps, ...reservationTypeProps };

  let rates = '';
  let minLOS = '';
  if (type === 'rates') {
    if (event.rate != null && event.rate !== '0') {
      rates = `$  ${event.rate}`;
      if (event.minLOS != null) {
        rates += ', ';
      }
    }
    if (event.minLOS != null) {
      minLOS = event.minLOS;
      if (event.minLOS === 1) {
        minLOS += ' Night';
      } else {
        minLOS += ' Nights';
      }
    }
  }

  return (
    <div
      className={classnames(classes.root, {
        [className]: !!className,
        continuesPrior: !!continuesPrior,
        continuesAfter: !!continuesAfter,
      })}
    >
      {!!src && <Image className={classes.image} src={src} />}
      {!!title && (
        <EllipsisText>
          <span className={classes.title}>{title}</span>
        </EllipsisText>
      )}
      {activities.map(({ type: activityType }) => {
        const activityProps = ACTIVITY_PROPS[activityType];
        if (!activityProps) {
          return null;
        }

        const { key, src: imgSrc } = activityProps;
        return <Image key={key} src={imgSrc} />;
      })}
      {type === 'rates' && (
        <EllipsisText>
          <span>
            {rates} {minLOS}
          </span>
        </EllipsisText>
      )}
    </div>
  );
};

export default React.memo(Event);
