import React, { useEffect } from 'react';
import '@/App.css';
import buildInfo from './buildInfo';
import { Loader } from 'semantic-ui-react';
import { Route, BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { store } from '@/store/store';
import { MediaContextProvider } from './media';
import LoginView from './views/LoginView';
import UnauthorizedView from './views/UnauthorizedView';
import SignOutView from './views/SignOutView';
import NotFoundView from './views/NotFoundView';
import WaveRoutes from '@/routes/WaveRoutes';
import { USER_ROUTES } from '@/constants/application';
import CustomRoutes from '@/components/CustomRoutes';

let persistor = persistStore(store);

const AppLoader = () => (
  <Loader id="full-screen-loader" size="big" indeterminate>
    Preparing app...
  </Loader>
);

const App = () => {
  useEffect(() => {
    const { buildDate, buildVersion } = buildInfo;
    console.log(`Build Number: ${buildVersion}`);
    console.log(`Build Date: ${new Date(buildDate).toString()}`);
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={<AppLoader />} persistor={persistor}>
        <MediaContextProvider>
          <BrowserRouter>
            <CustomRoutes>
              <Route path="/" element={<LoginView />} />
              <Route path="/login" element={<LoginView />} />
              <Route path="/unauthorized" element={<UnauthorizedView />} />
              <Route path="/sign-out" element={<SignOutView />} />
              <Route path="/notfound" element={<NotFoundView />} />
              <Route path={USER_ROUTES.userContext + '/*'} element={<WaveRoutes />} />
              <Route path="*" element={<NotFoundView />} />
              {/*<Route path="*" element={<Navigate to="/notfound" replace />} /> */}
            </CustomRoutes>
          </BrowserRouter>
        </MediaContextProvider>
      </PersistGate>
    </Provider>
  );
};

export default React.memo(App);
