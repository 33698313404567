import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import { createUseStyles } from 'react-jss';
import { sessionSelector } from '@/store/selector';
import Selects from '@/helpers/selects';

const useStyles = createUseStyles(
  {
    root: {
      '& .ui.image': {
        height: 16,
        marginTop: 'auto !important',
      },
    },
  },
  { name: 'PropertyDropdown' },
);

const PropertyDropdown = ({ onChange }) => {
  const classes = useStyles();
  const session = sessionSelector();
  const user = session.user;
  const userPropertyList = session.userPropertyList;

  const PROPERTIES = userPropertyList.map((property) => ({
    ...Selects.getPropertyAsOption(property),
    image: { src: '/assets/marker.svg', avatar: true },
  }));

  return (
    <Dropdown
      className={classes.root}
      options={PROPERTIES}
      selection
      onChange={onChange}
      value={user?.selectedProperty}
    />
  );
};

export default React.memo(PropertyDropdown);
