import React from 'react';
import { createUseStyles } from 'react-jss';
import WaveMenu from '../components/Menu/Menu';
import TopPanel from '../components/TopPanel';
import SidebarContainer from '../components/SidebarContainer';
import Colors from '@/constants/colors';
import Breakpoints from '@/constants/breakpoints';
import { Media } from '../media';
import { Outlet } from 'react-router-dom';

const useStyles = createUseStyles(
  {
    root: {
      display: 'flex',
      minHeight: '100vh',
      height: '100%',
    },
    left: {
      borderRight: `1px solid ${Colors.grayTwo}`,
      minHeight: '100vh',
      height: '100%',
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    },
    top: {
      maxWidth: '100vw',
      borderBottom: `1px solid ${Colors.grayTwo}`,
      padding: '0 1.5rem',
    },
    content: {
      display: 'flex',
      flex: '1 1 auto',
    },
    [`@media (max-width: ${Breakpoints.lg}px)`]: {
      content: {
        flexDirection: 'column',
      },
    },
  },
  { name: 'WaveLayout' },
);
const Content = React.memo(({ classes, showMenu, children }) => (
  <div className={classes.root}>
    <Media greaterThanOrEqual="lg">
      <div className={classes.left}>
        <WaveMenu />
      </div>
    </Media>
    <div className={classes.wrapper}>
      <div className={classes.top}>
        <TopPanel showMenu={showMenu} />
      </div>
      <div className={classes.content}>{children}</div>
    </div>
  </div>
));

const WaveLayout = () => {
  const classes = useStyles();

  return (
    <>
      <Media lessThan="lg">
        <SidebarContainer>
          {({ show }) => (
            <Content classes={classes} showMenu={show}>
              <Outlet />
            </Content>
          )}
        </SidebarContainer>
      </Media>
      <Media greaterThanOrEqual="lg">
        <Content classes={classes}>
          <Outlet />
        </Content>
      </Media>
    </>
  );
};

export default React.memo(WaveLayout);
